/* PAGINATION FIX */
a:not([href]):not([tabindex])
{
    color: #fff;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus
{
    color: #fff;
}

/* ROZNE */
.form-group.validate input, .form-group.validate select, .form-group.validate textarea {
    color: inherit
}
.dd-container, .dd-select, .dd-options {
    width: 100% !important;
}
.dd-option, .dd-selected {padding: 10px 10px 0 10px;}
.dd-option-text {line-height: 0 !important}
.form-control {height: auto !important}

/* LOGO */
.header-navbar .navbar-header .navbar-brand .brand-logo {
	width: 100%;
}
.header-navbar .navbar-header .navbar-brand {
	padding: 10px 0 17px;
}
#login .brand-logo {
    width: 100%;
    max-width: 420px;
}

/* BLOG */
#admin-blog td.akce {
    width: 20%
}
#admin-blog .dd-option-image, #admin-blog .dd-selected-image {
    max-height: 60px !important;
}

/* MEDIA */
#admin-media img.media-thumb {
   max-width: 150px;
   max-height: 80px;
}
.thumbnail .image_picker_image {
    max-width: 50px;
    max-height: 70px;
 }

/* TAGS */
#admin-tags .cp-box {
	height: 25px;
	display: inline-block;
    vertical-align: middle;
    color: #fff;
    padding: 2px 8px;
    border: 1px solid #000;
    min-width: 85px;
}

/* AJAX URL CHECK */
.ajax-url-check-icon.la-times-circle {
    color: red
}
.ajax-url-check-icon.la-check-square-o {
    color: rgb(31, 148, 31)
}
