.bg-gradient-x-white .card-header,
.bg-gradient-x-white .card-footer,
.bg-gradient-y-white .card-header,
.bg-gradient-y-white .card-footer,
.bg-gradient-directional-white .card-header,
.bg-gradient-directional-white .card-footer,
.bg-gradient-radial-white .card-header,
.bg-gradient-radial-white .card-footer,
.bg-gradient-striped-white .card-header,
.bg-gradient-striped-white .card-footer,
.bg-gradient-x2-white .card-header,
.bg-gradient-x2-white .card-footer,
.bg-gradient-y2-white .card-header,
.bg-gradient-y2-white .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-black .card-header,
.bg-gradient-x-black .card-footer,
.bg-gradient-y-black .card-header,
.bg-gradient-y-black .card-footer,
.bg-gradient-directional-black .card-header,
.bg-gradient-directional-black .card-footer,
.bg-gradient-radial-black .card-header,
.bg-gradient-radial-black .card-footer,
.bg-gradient-striped-black .card-header,
.bg-gradient-striped-black .card-footer,
.bg-gradient-x2-black .card-header,
.bg-gradient-x2-black .card-footer,
.bg-gradient-y2-black .card-header,
.bg-gradient-y2-black .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-primary
{
    background-image: -webkit-gradient(linear, left top, right top, from(#535be2), to(#949aef));
    background-image: -webkit-linear-gradient(left, #535be2 0%, #949aef 100%);
    background-image:    -moz-linear-gradient(left, #535be2 0%, #949aef 100%);
    background-image:      -o-linear-gradient(left, #535be2 0%, #949aef 100%);
    background-image:         linear-gradient(to right, #535be2 0%, #949aef 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-primary
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#535be2), to(#949aef));
    background-image: -webkit-linear-gradient(top, #535be2 0%, #949aef 100%);
    background-image:    -moz-linear-gradient(top, #535be2 0%, #949aef 100%);
    background-image:      -o-linear-gradient(top, #535be2 0%, #949aef 100%);
    background-image:         linear-gradient(to bottom, #535be2 0%, #949aef 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-primary
{
    background-image: -webkit-linear-gradient(45deg, #535be2, #949aef);
    background-image:    -moz-linear-gradient(45deg, #535be2, #949aef);
    background-image:      -o-linear-gradient(45deg, #535be2, #949aef);
    background-image:         linear-gradient(45deg, #535be2, #949aef);
    background-repeat: repeat-x;
}

.bg-gradient-x2-primary
{
    background-image: -webkit-gradient(linear, left top, right top, from(#949aef), color-stop(50%, #666ee8), to(#b3b7f4));
    background-image: -webkit-linear-gradient(left, #949aef, #666ee8 50%, #b3b7f4);
    background-image:    -moz-linear-gradient(left, #949aef, #666ee8 50%, #b3b7f4);
    background-image:      -o-linear-gradient(left, #949aef, #666ee8 50%, #b3b7f4);
    background-image:         linear-gradient(to right, #949aef, #666ee8 50%, #b3b7f4);
    background-repeat: no-repeat;
}

.bg-gradient-y2-primary
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#949aef), color-stop(50%, #666ee8), to(#b3b7f4));
    background-image: -webkit-linear-gradient(#949aef, #666ee8 50%, #b3b7f4);
    background-image:    -moz-linear-gradient(#949aef, #666ee8 50%, #b3b7f4);
    background-image:      -o-linear-gradient(#949aef, #666ee8 50%, #b3b7f4);
    background-image:         linear-gradient(#949aef, #666ee8 50%, #b3b7f4);
    background-repeat: no-repeat;
}

.bg-gradient-radial-primary
{
    background-image: -webkit-radial-gradient(circle, #535be2, #949aef);
    background-image:    -moz-radial-gradient(circle, #535be2, #949aef);
    background-image:      -o-radial-gradient(circle, #535be2, #949aef);
    background-image:         radial-gradient(circle, #535be2, #949aef);
    background-repeat: no-repeat;
}

.bg-gradient-striped-primary
{
    background-image: -webkit-linear-gradient(45deg, #b3b7f4 25%, transparent 25%, transparent 50%, #b3b7f4 50%, #b3b7f4 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #b3b7f4 25%, transparent 25%, transparent 50%, #b3b7f4 50%, #b3b7f4 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #b3b7f4 25%, transparent 25%, transparent 50%, #b3b7f4 50%, #b3b7f4 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #b3b7f4 25%, transparent 25%, transparent 50%, #b3b7f4 50%, #b3b7f4 75%, transparent 75%, transparent);
}

.bg-gradient-x-primary .card-header,
.bg-gradient-x-primary .card-footer,
.bg-gradient-y-primary .card-header,
.bg-gradient-y-primary .card-footer,
.bg-gradient-directional-primary .card-header,
.bg-gradient-directional-primary .card-footer,
.bg-gradient-radial-primary .card-header,
.bg-gradient-radial-primary .card-footer,
.bg-gradient-striped-primary .card-header,
.bg-gradient-striped-primary .card-footer,
.bg-gradient-x2-primary .card-header,
.bg-gradient-x2-primary .card-footer,
.bg-gradient-y2-primary .card-header,
.bg-gradient-y2-primary .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-success
{
    background-image: -webkit-gradient(linear, left top, right top, from(#1ec481), to(#69deb4));
    background-image: -webkit-linear-gradient(left, #1ec481 0%, #69deb4 100%);
    background-image:    -moz-linear-gradient(left, #1ec481 0%, #69deb4 100%);
    background-image:      -o-linear-gradient(left, #1ec481 0%, #69deb4 100%);
    background-image:         linear-gradient(to right, #1ec481 0%, #69deb4 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-success
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#1ec481), to(#69deb4));
    background-image: -webkit-linear-gradient(top, #1ec481 0%, #69deb4 100%);
    background-image:    -moz-linear-gradient(top, #1ec481 0%, #69deb4 100%);
    background-image:      -o-linear-gradient(top, #1ec481 0%, #69deb4 100%);
    background-image:         linear-gradient(to bottom, #1ec481 0%, #69deb4 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-success
{
    background-image: -webkit-linear-gradient(45deg, #1ec481, #69deb4);
    background-image:    -moz-linear-gradient(45deg, #1ec481, #69deb4);
    background-image:      -o-linear-gradient(45deg, #1ec481, #69deb4);
    background-image:         linear-gradient(45deg, #1ec481, #69deb4);
    background-repeat: repeat-x;
}

.bg-gradient-x2-success
{
    background-image: -webkit-gradient(linear, left top, right top, from(#69deb4), color-stop(50%, #28d094), to(#94e8ca));
    background-image: -webkit-linear-gradient(left, #69deb4, #28d094 50%, #94e8ca);
    background-image:    -moz-linear-gradient(left, #69deb4, #28d094 50%, #94e8ca);
    background-image:      -o-linear-gradient(left, #69deb4, #28d094 50%, #94e8ca);
    background-image:         linear-gradient(to right, #69deb4, #28d094 50%, #94e8ca);
    background-repeat: no-repeat;
}

.bg-gradient-y2-success
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#69deb4), color-stop(50%, #28d094), to(#94e8ca));
    background-image: -webkit-linear-gradient(#69deb4, #28d094 50%, #94e8ca);
    background-image:    -moz-linear-gradient(#69deb4, #28d094 50%, #94e8ca);
    background-image:      -o-linear-gradient(#69deb4, #28d094 50%, #94e8ca);
    background-image:         linear-gradient(#69deb4, #28d094 50%, #94e8ca);
    background-repeat: no-repeat;
}

.bg-gradient-radial-success
{
    background-image: -webkit-radial-gradient(circle, #1ec481, #69deb4);
    background-image:    -moz-radial-gradient(circle, #1ec481, #69deb4);
    background-image:      -o-radial-gradient(circle, #1ec481, #69deb4);
    background-image:         radial-gradient(circle, #1ec481, #69deb4);
    background-repeat: no-repeat;
}

.bg-gradient-striped-success
{
    background-image: -webkit-linear-gradient(45deg, #94e8ca 25%, transparent 25%, transparent 50%, #94e8ca 50%, #94e8ca 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #94e8ca 25%, transparent 25%, transparent 50%, #94e8ca 50%, #94e8ca 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #94e8ca 25%, transparent 25%, transparent 50%, #94e8ca 50%, #94e8ca 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #94e8ca 25%, transparent 25%, transparent 50%, #94e8ca 50%, #94e8ca 75%, transparent 75%, transparent);
}

.bg-gradient-x-success .card-header,
.bg-gradient-x-success .card-footer,
.bg-gradient-y-success .card-header,
.bg-gradient-y-success .card-footer,
.bg-gradient-directional-success .card-header,
.bg-gradient-directional-success .card-footer,
.bg-gradient-radial-success .card-header,
.bg-gradient-radial-success .card-footer,
.bg-gradient-striped-success .card-header,
.bg-gradient-striped-success .card-footer,
.bg-gradient-x2-success .card-header,
.bg-gradient-x2-success .card-footer,
.bg-gradient-y2-success .card-header,
.bg-gradient-y2-success .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-info
{
    background-image: -webkit-gradient(linear, left top, right top, from(#168dee), to(#62bcf6));
    background-image: -webkit-linear-gradient(left, #168dee 0%, #62bcf6 100%);
    background-image:    -moz-linear-gradient(left, #168dee 0%, #62bcf6 100%);
    background-image:      -o-linear-gradient(left, #168dee 0%, #62bcf6 100%);
    background-image:         linear-gradient(to right, #168dee 0%, #62bcf6 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-info
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#168dee), to(#62bcf6));
    background-image: -webkit-linear-gradient(top, #168dee 0%, #62bcf6 100%);
    background-image:    -moz-linear-gradient(top, #168dee 0%, #62bcf6 100%);
    background-image:      -o-linear-gradient(top, #168dee 0%, #62bcf6 100%);
    background-image:         linear-gradient(to bottom, #168dee 0%, #62bcf6 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-info
{
    background-image: -webkit-linear-gradient(45deg, #168dee, #62bcf6);
    background-image:    -moz-linear-gradient(45deg, #168dee, #62bcf6);
    background-image:      -o-linear-gradient(45deg, #168dee, #62bcf6);
    background-image:         linear-gradient(45deg, #168dee, #62bcf6);
    background-repeat: repeat-x;
}

.bg-gradient-x2-info
{
    background-image: -webkit-gradient(linear, left top, right top, from(#62bcf6), color-stop(50%, #1e9ff2), to(#8fcff9));
    background-image: -webkit-linear-gradient(left, #62bcf6, #1e9ff2 50%, #8fcff9);
    background-image:    -moz-linear-gradient(left, #62bcf6, #1e9ff2 50%, #8fcff9);
    background-image:      -o-linear-gradient(left, #62bcf6, #1e9ff2 50%, #8fcff9);
    background-image:         linear-gradient(to right, #62bcf6, #1e9ff2 50%, #8fcff9);
    background-repeat: no-repeat;
}

.bg-gradient-y2-info
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#62bcf6), color-stop(50%, #1e9ff2), to(#8fcff9));
    background-image: -webkit-linear-gradient(#62bcf6, #1e9ff2 50%, #8fcff9);
    background-image:    -moz-linear-gradient(#62bcf6, #1e9ff2 50%, #8fcff9);
    background-image:      -o-linear-gradient(#62bcf6, #1e9ff2 50%, #8fcff9);
    background-image:         linear-gradient(#62bcf6, #1e9ff2 50%, #8fcff9);
    background-repeat: no-repeat;
}

.bg-gradient-radial-info
{
    background-image: -webkit-radial-gradient(circle, #168dee, #62bcf6);
    background-image:    -moz-radial-gradient(circle, #168dee, #62bcf6);
    background-image:      -o-radial-gradient(circle, #168dee, #62bcf6);
    background-image:         radial-gradient(circle, #168dee, #62bcf6);
    background-repeat: no-repeat;
}

.bg-gradient-striped-info
{
    background-image: -webkit-linear-gradient(45deg, #8fcff9 25%, transparent 25%, transparent 50%, #8fcff9 50%, #8fcff9 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #8fcff9 25%, transparent 25%, transparent 50%, #8fcff9 50%, #8fcff9 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #8fcff9 25%, transparent 25%, transparent 50%, #8fcff9 50%, #8fcff9 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #8fcff9 25%, transparent 25%, transparent 50%, #8fcff9 50%, #8fcff9 75%, transparent 75%, transparent);
}

.bg-gradient-x-info .card-header,
.bg-gradient-x-info .card-footer,
.bg-gradient-y-info .card-header,
.bg-gradient-y-info .card-footer,
.bg-gradient-directional-info .card-header,
.bg-gradient-directional-info .card-footer,
.bg-gradient-radial-info .card-header,
.bg-gradient-radial-info .card-footer,
.bg-gradient-striped-info .card-header,
.bg-gradient-striped-info .card-footer,
.bg-gradient-x2-info .card-header,
.bg-gradient-x2-info .card-footer,
.bg-gradient-y2-info .card-header,
.bg-gradient-y2-info .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-warning
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ff7e39), to(#ffb280));
    background-image: -webkit-linear-gradient(left, #ff7e39 0%, #ffb280 100%);
    background-image:    -moz-linear-gradient(left, #ff7e39 0%, #ffb280 100%);
    background-image:      -o-linear-gradient(left, #ff7e39 0%, #ffb280 100%);
    background-image:         linear-gradient(to right, #ff7e39 0%, #ffb280 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-warning
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff7e39), to(#ffb280));
    background-image: -webkit-linear-gradient(top, #ff7e39 0%, #ffb280 100%);
    background-image:    -moz-linear-gradient(top, #ff7e39 0%, #ffb280 100%);
    background-image:      -o-linear-gradient(top, #ff7e39 0%, #ffb280 100%);
    background-image:         linear-gradient(to bottom, #ff7e39 0%, #ffb280 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-warning
{
    background-image: -webkit-linear-gradient(45deg, #ff7e39, #ffb280);
    background-image:    -moz-linear-gradient(45deg, #ff7e39, #ffb280);
    background-image:      -o-linear-gradient(45deg, #ff7e39, #ffb280);
    background-image:         linear-gradient(45deg, #ff7e39, #ffb280);
    background-repeat: repeat-x;
}

.bg-gradient-x2-warning
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ffb280), color-stop(50%, #ff9149), to(#ffc8a4));
    background-image: -webkit-linear-gradient(left, #ffb280, #ff9149 50%, #ffc8a4);
    background-image:    -moz-linear-gradient(left, #ffb280, #ff9149 50%, #ffc8a4);
    background-image:      -o-linear-gradient(left, #ffb280, #ff9149 50%, #ffc8a4);
    background-image:         linear-gradient(to right, #ffb280, #ff9149 50%, #ffc8a4);
    background-repeat: no-repeat;
}

.bg-gradient-y2-warning
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffb280), color-stop(50%, #ff9149), to(#ffc8a4));
    background-image: -webkit-linear-gradient(#ffb280, #ff9149 50%, #ffc8a4);
    background-image:    -moz-linear-gradient(#ffb280, #ff9149 50%, #ffc8a4);
    background-image:      -o-linear-gradient(#ffb280, #ff9149 50%, #ffc8a4);
    background-image:         linear-gradient(#ffb280, #ff9149 50%, #ffc8a4);
    background-repeat: no-repeat;
}

.bg-gradient-radial-warning
{
    background-image: -webkit-radial-gradient(circle, #ff7e39, #ffb280);
    background-image:    -moz-radial-gradient(circle, #ff7e39, #ffb280);
    background-image:      -o-radial-gradient(circle, #ff7e39, #ffb280);
    background-image:         radial-gradient(circle, #ff7e39, #ffb280);
    background-repeat: no-repeat;
}

.bg-gradient-striped-warning
{
    background-image: -webkit-linear-gradient(45deg, #ffc8a4 25%, transparent 25%, transparent 50%, #ffc8a4 50%, #ffc8a4 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #ffc8a4 25%, transparent 25%, transparent 50%, #ffc8a4 50%, #ffc8a4 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #ffc8a4 25%, transparent 25%, transparent 50%, #ffc8a4 50%, #ffc8a4 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #ffc8a4 25%, transparent 25%, transparent 50%, #ffc8a4 50%, #ffc8a4 75%, transparent 75%, transparent);
}

.bg-gradient-x-warning .card-header,
.bg-gradient-x-warning .card-footer,
.bg-gradient-y-warning .card-header,
.bg-gradient-y-warning .card-footer,
.bg-gradient-directional-warning .card-header,
.bg-gradient-directional-warning .card-footer,
.bg-gradient-radial-warning .card-header,
.bg-gradient-radial-warning .card-footer,
.bg-gradient-striped-warning .card-header,
.bg-gradient-striped-warning .card-footer,
.bg-gradient-x2-warning .card-header,
.bg-gradient-x2-warning .card-footer,
.bg-gradient-y2-warning .card-header,
.bg-gradient-y2-warning .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-danger
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ff394f), to(#ff8090));
    background-image: -webkit-linear-gradient(left, #ff394f 0%, #ff8090 100%);
    background-image:    -moz-linear-gradient(left, #ff394f 0%, #ff8090 100%);
    background-image:      -o-linear-gradient(left, #ff394f 0%, #ff8090 100%);
    background-image:         linear-gradient(to right, #ff394f 0%, #ff8090 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-danger
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff394f), to(#ff8090));
    background-image: -webkit-linear-gradient(top, #ff394f 0%, #ff8090 100%);
    background-image:    -moz-linear-gradient(top, #ff394f 0%, #ff8090 100%);
    background-image:      -o-linear-gradient(top, #ff394f 0%, #ff8090 100%);
    background-image:         linear-gradient(to bottom, #ff394f 0%, #ff8090 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-danger
{
    background-image: -webkit-linear-gradient(45deg, #ff394f, #ff8090);
    background-image:    -moz-linear-gradient(45deg, #ff394f, #ff8090);
    background-image:      -o-linear-gradient(45deg, #ff394f, #ff8090);
    background-image:         linear-gradient(45deg, #ff394f, #ff8090);
    background-repeat: repeat-x;
}

.bg-gradient-x2-danger
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ff8090), color-stop(50%, #ff4961), to(#ffa4b0));
    background-image: -webkit-linear-gradient(left, #ff8090, #ff4961 50%, #ffa4b0);
    background-image:    -moz-linear-gradient(left, #ff8090, #ff4961 50%, #ffa4b0);
    background-image:      -o-linear-gradient(left, #ff8090, #ff4961 50%, #ffa4b0);
    background-image:         linear-gradient(to right, #ff8090, #ff4961 50%, #ffa4b0);
    background-repeat: no-repeat;
}

.bg-gradient-y2-danger
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff8090), color-stop(50%, #ff4961), to(#ffa4b0));
    background-image: -webkit-linear-gradient(#ff8090, #ff4961 50%, #ffa4b0);
    background-image:    -moz-linear-gradient(#ff8090, #ff4961 50%, #ffa4b0);
    background-image:      -o-linear-gradient(#ff8090, #ff4961 50%, #ffa4b0);
    background-image:         linear-gradient(#ff8090, #ff4961 50%, #ffa4b0);
    background-repeat: no-repeat;
}

.bg-gradient-radial-danger
{
    background-image: -webkit-radial-gradient(circle, #ff394f, #ff8090);
    background-image:    -moz-radial-gradient(circle, #ff394f, #ff8090);
    background-image:      -o-radial-gradient(circle, #ff394f, #ff8090);
    background-image:         radial-gradient(circle, #ff394f, #ff8090);
    background-repeat: no-repeat;
}

.bg-gradient-striped-danger
{
    background-image: -webkit-linear-gradient(45deg, #ffa4b0 25%, transparent 25%, transparent 50%, #ffa4b0 50%, #ffa4b0 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #ffa4b0 25%, transparent 25%, transparent 50%, #ffa4b0 50%, #ffa4b0 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #ffa4b0 25%, transparent 25%, transparent 50%, #ffa4b0 50%, #ffa4b0 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #ffa4b0 25%, transparent 25%, transparent 50%, #ffa4b0 50%, #ffa4b0 75%, transparent 75%, transparent);
}

.bg-gradient-x-danger .card-header,
.bg-gradient-x-danger .card-footer,
.bg-gradient-y-danger .card-header,
.bg-gradient-y-danger .card-footer,
.bg-gradient-directional-danger .card-header,
.bg-gradient-directional-danger .card-footer,
.bg-gradient-radial-danger .card-header,
.bg-gradient-radial-danger .card-footer,
.bg-gradient-striped-danger .card-header,
.bg-gradient-striped-danger .card-footer,
.bg-gradient-x2-danger .card-header,
.bg-gradient-x2-danger .card-footer,
.bg-gradient-y2-danger .card-header,
.bg-gradient-y2-danger .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-red
{
    background-image: -webkit-gradient(linear, left top, right top, from(#d32f2f), to(#e57373));
    background-image: -webkit-linear-gradient(left, #d32f2f 0%, #e57373 100%);
    background-image:    -moz-linear-gradient(left, #d32f2f 0%, #e57373 100%);
    background-image:      -o-linear-gradient(left, #d32f2f 0%, #e57373 100%);
    background-image:         linear-gradient(to right, #d32f2f 0%, #e57373 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-red
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#d32f2f), to(#e57373));
    background-image: -webkit-linear-gradient(top, #d32f2f 0%, #e57373 100%);
    background-image:    -moz-linear-gradient(top, #d32f2f 0%, #e57373 100%);
    background-image:      -o-linear-gradient(top, #d32f2f 0%, #e57373 100%);
    background-image:         linear-gradient(to bottom, #d32f2f 0%, #e57373 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-red
{
    background-image: -webkit-linear-gradient(45deg, #d32f2f, #e57373);
    background-image:    -moz-linear-gradient(45deg, #d32f2f, #e57373);
    background-image:      -o-linear-gradient(45deg, #d32f2f, #e57373);
    background-image:         linear-gradient(45deg, #d32f2f, #e57373);
    background-repeat: repeat-x;
}

.bg-gradient-x2-red
{
    background-image: -webkit-gradient(linear, left top, right top, from(#e57373), color-stop(50%, #f44336), to(#ef9a9a));
    background-image: -webkit-linear-gradient(left, #e57373, #f44336 50%, #ef9a9a);
    background-image:    -moz-linear-gradient(left, #e57373, #f44336 50%, #ef9a9a);
    background-image:      -o-linear-gradient(left, #e57373, #f44336 50%, #ef9a9a);
    background-image:         linear-gradient(to right, #e57373, #f44336 50%, #ef9a9a);
    background-repeat: no-repeat;
}

.bg-gradient-y2-red
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#e57373), color-stop(50%, #f44336), to(#ef9a9a));
    background-image: -webkit-linear-gradient(#e57373, #f44336 50%, #ef9a9a);
    background-image:    -moz-linear-gradient(#e57373, #f44336 50%, #ef9a9a);
    background-image:      -o-linear-gradient(#e57373, #f44336 50%, #ef9a9a);
    background-image:         linear-gradient(#e57373, #f44336 50%, #ef9a9a);
    background-repeat: no-repeat;
}

.bg-gradient-radial-red
{
    background-image: -webkit-radial-gradient(circle, #d32f2f, #e57373);
    background-image:    -moz-radial-gradient(circle, #d32f2f, #e57373);
    background-image:      -o-radial-gradient(circle, #d32f2f, #e57373);
    background-image:         radial-gradient(circle, #d32f2f, #e57373);
    background-repeat: no-repeat;
}

.bg-gradient-striped-red
{
    background-image: -webkit-linear-gradient(45deg, #ef9a9a 25%, transparent 25%, transparent 50%, #ef9a9a 50%, #ef9a9a 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #ef9a9a 25%, transparent 25%, transparent 50%, #ef9a9a 50%, #ef9a9a 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #ef9a9a 25%, transparent 25%, transparent 50%, #ef9a9a 50%, #ef9a9a 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #ef9a9a 25%, transparent 25%, transparent 50%, #ef9a9a 50%, #ef9a9a 75%, transparent 75%, transparent);
}

.bg-gradient-x-red .card-header,
.bg-gradient-x-red .card-footer,
.bg-gradient-y-red .card-header,
.bg-gradient-y-red .card-footer,
.bg-gradient-directional-red .card-header,
.bg-gradient-directional-red .card-footer,
.bg-gradient-radial-red .card-header,
.bg-gradient-radial-red .card-footer,
.bg-gradient-striped-red .card-header,
.bg-gradient-striped-red .card-footer,
.bg-gradient-x2-red .card-header,
.bg-gradient-x2-red .card-footer,
.bg-gradient-y2-red .card-header,
.bg-gradient-y2-red .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-pink
{
    background-image: -webkit-gradient(linear, left top, right top, from(#c2185b), to(#f06292));
    background-image: -webkit-linear-gradient(left, #c2185b 0%, #f06292 100%);
    background-image:    -moz-linear-gradient(left, #c2185b 0%, #f06292 100%);
    background-image:      -o-linear-gradient(left, #c2185b 0%, #f06292 100%);
    background-image:         linear-gradient(to right, #c2185b 0%, #f06292 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-pink
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#c2185b), to(#f06292));
    background-image: -webkit-linear-gradient(top, #c2185b 0%, #f06292 100%);
    background-image:    -moz-linear-gradient(top, #c2185b 0%, #f06292 100%);
    background-image:      -o-linear-gradient(top, #c2185b 0%, #f06292 100%);
    background-image:         linear-gradient(to bottom, #c2185b 0%, #f06292 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-pink
{
    background-image: -webkit-linear-gradient(45deg, #c2185b, #f06292);
    background-image:    -moz-linear-gradient(45deg, #c2185b, #f06292);
    background-image:      -o-linear-gradient(45deg, #c2185b, #f06292);
    background-image:         linear-gradient(45deg, #c2185b, #f06292);
    background-repeat: repeat-x;
}

.bg-gradient-x2-pink
{
    background-image: -webkit-gradient(linear, left top, right top, from(#f06292), color-stop(50%, #e91e63), to(#f48fb1));
    background-image: -webkit-linear-gradient(left, #f06292, #e91e63 50%, #f48fb1);
    background-image:    -moz-linear-gradient(left, #f06292, #e91e63 50%, #f48fb1);
    background-image:      -o-linear-gradient(left, #f06292, #e91e63 50%, #f48fb1);
    background-image:         linear-gradient(to right, #f06292, #e91e63 50%, #f48fb1);
    background-repeat: no-repeat;
}

.bg-gradient-y2-pink
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f06292), color-stop(50%, #e91e63), to(#f48fb1));
    background-image: -webkit-linear-gradient(#f06292, #e91e63 50%, #f48fb1);
    background-image:    -moz-linear-gradient(#f06292, #e91e63 50%, #f48fb1);
    background-image:      -o-linear-gradient(#f06292, #e91e63 50%, #f48fb1);
    background-image:         linear-gradient(#f06292, #e91e63 50%, #f48fb1);
    background-repeat: no-repeat;
}

.bg-gradient-radial-pink
{
    background-image: -webkit-radial-gradient(circle, #c2185b, #f06292);
    background-image:    -moz-radial-gradient(circle, #c2185b, #f06292);
    background-image:      -o-radial-gradient(circle, #c2185b, #f06292);
    background-image:         radial-gradient(circle, #c2185b, #f06292);
    background-repeat: no-repeat;
}

.bg-gradient-striped-pink
{
    background-image: -webkit-linear-gradient(45deg, #f48fb1 25%, transparent 25%, transparent 50%, #f48fb1 50%, #f48fb1 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #f48fb1 25%, transparent 25%, transparent 50%, #f48fb1 50%, #f48fb1 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #f48fb1 25%, transparent 25%, transparent 50%, #f48fb1 50%, #f48fb1 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #f48fb1 25%, transparent 25%, transparent 50%, #f48fb1 50%, #f48fb1 75%, transparent 75%, transparent);
}

.bg-gradient-x-pink .card-header,
.bg-gradient-x-pink .card-footer,
.bg-gradient-y-pink .card-header,
.bg-gradient-y-pink .card-footer,
.bg-gradient-directional-pink .card-header,
.bg-gradient-directional-pink .card-footer,
.bg-gradient-radial-pink .card-header,
.bg-gradient-radial-pink .card-footer,
.bg-gradient-striped-pink .card-header,
.bg-gradient-striped-pink .card-footer,
.bg-gradient-x2-pink .card-header,
.bg-gradient-x2-pink .card-footer,
.bg-gradient-y2-pink .card-header,
.bg-gradient-y2-pink .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-purple
{
    background-image: -webkit-gradient(linear, left top, right top, from(#7b1fa2), to(#ba68c8));
    background-image: -webkit-linear-gradient(left, #7b1fa2 0%, #ba68c8 100%);
    background-image:    -moz-linear-gradient(left, #7b1fa2 0%, #ba68c8 100%);
    background-image:      -o-linear-gradient(left, #7b1fa2 0%, #ba68c8 100%);
    background-image:         linear-gradient(to right, #7b1fa2 0%, #ba68c8 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-purple
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#7b1fa2), to(#ba68c8));
    background-image: -webkit-linear-gradient(top, #7b1fa2 0%, #ba68c8 100%);
    background-image:    -moz-linear-gradient(top, #7b1fa2 0%, #ba68c8 100%);
    background-image:      -o-linear-gradient(top, #7b1fa2 0%, #ba68c8 100%);
    background-image:         linear-gradient(to bottom, #7b1fa2 0%, #ba68c8 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-purple
{
    background-image: -webkit-linear-gradient(45deg, #7b1fa2, #ba68c8);
    background-image:    -moz-linear-gradient(45deg, #7b1fa2, #ba68c8);
    background-image:      -o-linear-gradient(45deg, #7b1fa2, #ba68c8);
    background-image:         linear-gradient(45deg, #7b1fa2, #ba68c8);
    background-repeat: repeat-x;
}

.bg-gradient-x2-purple
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ba68c8), color-stop(50%, #9c27b0), to(#ce93d8));
    background-image: -webkit-linear-gradient(left, #ba68c8, #9c27b0 50%, #ce93d8);
    background-image:    -moz-linear-gradient(left, #ba68c8, #9c27b0 50%, #ce93d8);
    background-image:      -o-linear-gradient(left, #ba68c8, #9c27b0 50%, #ce93d8);
    background-image:         linear-gradient(to right, #ba68c8, #9c27b0 50%, #ce93d8);
    background-repeat: no-repeat;
}

.bg-gradient-y2-purple
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ba68c8), color-stop(50%, #9c27b0), to(#ce93d8));
    background-image: -webkit-linear-gradient(#ba68c8, #9c27b0 50%, #ce93d8);
    background-image:    -moz-linear-gradient(#ba68c8, #9c27b0 50%, #ce93d8);
    background-image:      -o-linear-gradient(#ba68c8, #9c27b0 50%, #ce93d8);
    background-image:         linear-gradient(#ba68c8, #9c27b0 50%, #ce93d8);
    background-repeat: no-repeat;
}

.bg-gradient-radial-purple
{
    background-image: -webkit-radial-gradient(circle, #7b1fa2, #ba68c8);
    background-image:    -moz-radial-gradient(circle, #7b1fa2, #ba68c8);
    background-image:      -o-radial-gradient(circle, #7b1fa2, #ba68c8);
    background-image:         radial-gradient(circle, #7b1fa2, #ba68c8);
    background-repeat: no-repeat;
}

.bg-gradient-striped-purple
{
    background-image: -webkit-linear-gradient(45deg, #ce93d8 25%, transparent 25%, transparent 50%, #ce93d8 50%, #ce93d8 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #ce93d8 25%, transparent 25%, transparent 50%, #ce93d8 50%, #ce93d8 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #ce93d8 25%, transparent 25%, transparent 50%, #ce93d8 50%, #ce93d8 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #ce93d8 25%, transparent 25%, transparent 50%, #ce93d8 50%, #ce93d8 75%, transparent 75%, transparent);
}

.bg-gradient-x-purple .card-header,
.bg-gradient-x-purple .card-footer,
.bg-gradient-y-purple .card-header,
.bg-gradient-y-purple .card-footer,
.bg-gradient-directional-purple .card-header,
.bg-gradient-directional-purple .card-footer,
.bg-gradient-radial-purple .card-header,
.bg-gradient-radial-purple .card-footer,
.bg-gradient-striped-purple .card-header,
.bg-gradient-striped-purple .card-footer,
.bg-gradient-x2-purple .card-header,
.bg-gradient-x2-purple .card-footer,
.bg-gradient-y2-purple .card-header,
.bg-gradient-y2-purple .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-blue
{
    background-image: -webkit-gradient(linear, left top, right top, from(#1976d2), to(#64b5f6));
    background-image: -webkit-linear-gradient(left, #1976d2 0%, #64b5f6 100%);
    background-image:    -moz-linear-gradient(left, #1976d2 0%, #64b5f6 100%);
    background-image:      -o-linear-gradient(left, #1976d2 0%, #64b5f6 100%);
    background-image:         linear-gradient(to right, #1976d2 0%, #64b5f6 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-blue
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#1976d2), to(#64b5f6));
    background-image: -webkit-linear-gradient(top, #1976d2 0%, #64b5f6 100%);
    background-image:    -moz-linear-gradient(top, #1976d2 0%, #64b5f6 100%);
    background-image:      -o-linear-gradient(top, #1976d2 0%, #64b5f6 100%);
    background-image:         linear-gradient(to bottom, #1976d2 0%, #64b5f6 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-blue
{
    background-image: -webkit-linear-gradient(45deg, #1976d2, #64b5f6);
    background-image:    -moz-linear-gradient(45deg, #1976d2, #64b5f6);
    background-image:      -o-linear-gradient(45deg, #1976d2, #64b5f6);
    background-image:         linear-gradient(45deg, #1976d2, #64b5f6);
    background-repeat: repeat-x;
}

.bg-gradient-x2-blue
{
    background-image: -webkit-gradient(linear, left top, right top, from(#64b5f6), color-stop(50%, #2196f3), to(#90caf9));
    background-image: -webkit-linear-gradient(left, #64b5f6, #2196f3 50%, #90caf9);
    background-image:    -moz-linear-gradient(left, #64b5f6, #2196f3 50%, #90caf9);
    background-image:      -o-linear-gradient(left, #64b5f6, #2196f3 50%, #90caf9);
    background-image:         linear-gradient(to right, #64b5f6, #2196f3 50%, #90caf9);
    background-repeat: no-repeat;
}

.bg-gradient-y2-blue
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#64b5f6), color-stop(50%, #2196f3), to(#90caf9));
    background-image: -webkit-linear-gradient(#64b5f6, #2196f3 50%, #90caf9);
    background-image:    -moz-linear-gradient(#64b5f6, #2196f3 50%, #90caf9);
    background-image:      -o-linear-gradient(#64b5f6, #2196f3 50%, #90caf9);
    background-image:         linear-gradient(#64b5f6, #2196f3 50%, #90caf9);
    background-repeat: no-repeat;
}

.bg-gradient-radial-blue
{
    background-image: -webkit-radial-gradient(circle, #1976d2, #64b5f6);
    background-image:    -moz-radial-gradient(circle, #1976d2, #64b5f6);
    background-image:      -o-radial-gradient(circle, #1976d2, #64b5f6);
    background-image:         radial-gradient(circle, #1976d2, #64b5f6);
    background-repeat: no-repeat;
}

.bg-gradient-striped-blue
{
    background-image: -webkit-linear-gradient(45deg, #90caf9 25%, transparent 25%, transparent 50%, #90caf9 50%, #90caf9 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #90caf9 25%, transparent 25%, transparent 50%, #90caf9 50%, #90caf9 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #90caf9 25%, transparent 25%, transparent 50%, #90caf9 50%, #90caf9 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #90caf9 25%, transparent 25%, transparent 50%, #90caf9 50%, #90caf9 75%, transparent 75%, transparent);
}

.bg-gradient-x-blue .card-header,
.bg-gradient-x-blue .card-footer,
.bg-gradient-y-blue .card-header,
.bg-gradient-y-blue .card-footer,
.bg-gradient-directional-blue .card-header,
.bg-gradient-directional-blue .card-footer,
.bg-gradient-radial-blue .card-header,
.bg-gradient-radial-blue .card-footer,
.bg-gradient-striped-blue .card-header,
.bg-gradient-striped-blue .card-footer,
.bg-gradient-x2-blue .card-header,
.bg-gradient-x2-blue .card-footer,
.bg-gradient-y2-blue .card-header,
.bg-gradient-y2-blue .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-cyan
{
    background-image: -webkit-gradient(linear, left top, right top, from(#0097a7), to(#4dd0e1));
    background-image: -webkit-linear-gradient(left, #0097a7 0%, #4dd0e1 100%);
    background-image:    -moz-linear-gradient(left, #0097a7 0%, #4dd0e1 100%);
    background-image:      -o-linear-gradient(left, #0097a7 0%, #4dd0e1 100%);
    background-image:         linear-gradient(to right, #0097a7 0%, #4dd0e1 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-cyan
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#0097a7), to(#4dd0e1));
    background-image: -webkit-linear-gradient(top, #0097a7 0%, #4dd0e1 100%);
    background-image:    -moz-linear-gradient(top, #0097a7 0%, #4dd0e1 100%);
    background-image:      -o-linear-gradient(top, #0097a7 0%, #4dd0e1 100%);
    background-image:         linear-gradient(to bottom, #0097a7 0%, #4dd0e1 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-cyan
{
    background-image: -webkit-linear-gradient(45deg, #0097a7, #4dd0e1);
    background-image:    -moz-linear-gradient(45deg, #0097a7, #4dd0e1);
    background-image:      -o-linear-gradient(45deg, #0097a7, #4dd0e1);
    background-image:         linear-gradient(45deg, #0097a7, #4dd0e1);
    background-repeat: repeat-x;
}

.bg-gradient-x2-cyan
{
    background-image: -webkit-gradient(linear, left top, right top, from(#4dd0e1), color-stop(50%, #00bcd4), to(#80deea));
    background-image: -webkit-linear-gradient(left, #4dd0e1, #00bcd4 50%, #80deea);
    background-image:    -moz-linear-gradient(left, #4dd0e1, #00bcd4 50%, #80deea);
    background-image:      -o-linear-gradient(left, #4dd0e1, #00bcd4 50%, #80deea);
    background-image:         linear-gradient(to right, #4dd0e1, #00bcd4 50%, #80deea);
    background-repeat: no-repeat;
}

.bg-gradient-y2-cyan
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4dd0e1), color-stop(50%, #00bcd4), to(#80deea));
    background-image: -webkit-linear-gradient(#4dd0e1, #00bcd4 50%, #80deea);
    background-image:    -moz-linear-gradient(#4dd0e1, #00bcd4 50%, #80deea);
    background-image:      -o-linear-gradient(#4dd0e1, #00bcd4 50%, #80deea);
    background-image:         linear-gradient(#4dd0e1, #00bcd4 50%, #80deea);
    background-repeat: no-repeat;
}

.bg-gradient-radial-cyan
{
    background-image: -webkit-radial-gradient(circle, #0097a7, #4dd0e1);
    background-image:    -moz-radial-gradient(circle, #0097a7, #4dd0e1);
    background-image:      -o-radial-gradient(circle, #0097a7, #4dd0e1);
    background-image:         radial-gradient(circle, #0097a7, #4dd0e1);
    background-repeat: no-repeat;
}

.bg-gradient-striped-cyan
{
    background-image: -webkit-linear-gradient(45deg, #80deea 25%, transparent 25%, transparent 50%, #80deea 50%, #80deea 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #80deea 25%, transparent 25%, transparent 50%, #80deea 50%, #80deea 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #80deea 25%, transparent 25%, transparent 50%, #80deea 50%, #80deea 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #80deea 25%, transparent 25%, transparent 50%, #80deea 50%, #80deea 75%, transparent 75%, transparent);
}

.bg-gradient-x-cyan .card-header,
.bg-gradient-x-cyan .card-footer,
.bg-gradient-y-cyan .card-header,
.bg-gradient-y-cyan .card-footer,
.bg-gradient-directional-cyan .card-header,
.bg-gradient-directional-cyan .card-footer,
.bg-gradient-radial-cyan .card-header,
.bg-gradient-radial-cyan .card-footer,
.bg-gradient-striped-cyan .card-header,
.bg-gradient-striped-cyan .card-footer,
.bg-gradient-x2-cyan .card-header,
.bg-gradient-x2-cyan .card-footer,
.bg-gradient-y2-cyan .card-header,
.bg-gradient-y2-cyan .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-teal
{
    background-image: -webkit-gradient(linear, left top, right top, from(#00796b), to(#4db6ac));
    background-image: -webkit-linear-gradient(left, #00796b 0%, #4db6ac 100%);
    background-image:    -moz-linear-gradient(left, #00796b 0%, #4db6ac 100%);
    background-image:      -o-linear-gradient(left, #00796b 0%, #4db6ac 100%);
    background-image:         linear-gradient(to right, #00796b 0%, #4db6ac 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-teal
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#00796b), to(#4db6ac));
    background-image: -webkit-linear-gradient(top, #00796b 0%, #4db6ac 100%);
    background-image:    -moz-linear-gradient(top, #00796b 0%, #4db6ac 100%);
    background-image:      -o-linear-gradient(top, #00796b 0%, #4db6ac 100%);
    background-image:         linear-gradient(to bottom, #00796b 0%, #4db6ac 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-teal
{
    background-image: -webkit-linear-gradient(45deg, #00796b, #4db6ac);
    background-image:    -moz-linear-gradient(45deg, #00796b, #4db6ac);
    background-image:      -o-linear-gradient(45deg, #00796b, #4db6ac);
    background-image:         linear-gradient(45deg, #00796b, #4db6ac);
    background-repeat: repeat-x;
}

.bg-gradient-x2-teal
{
    background-image: -webkit-gradient(linear, left top, right top, from(#4db6ac), color-stop(50%, #009688), to(#80cbc4));
    background-image: -webkit-linear-gradient(left, #4db6ac, #009688 50%, #80cbc4);
    background-image:    -moz-linear-gradient(left, #4db6ac, #009688 50%, #80cbc4);
    background-image:      -o-linear-gradient(left, #4db6ac, #009688 50%, #80cbc4);
    background-image:         linear-gradient(to right, #4db6ac, #009688 50%, #80cbc4);
    background-repeat: no-repeat;
}

.bg-gradient-y2-teal
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#4db6ac), color-stop(50%, #009688), to(#80cbc4));
    background-image: -webkit-linear-gradient(#4db6ac, #009688 50%, #80cbc4);
    background-image:    -moz-linear-gradient(#4db6ac, #009688 50%, #80cbc4);
    background-image:      -o-linear-gradient(#4db6ac, #009688 50%, #80cbc4);
    background-image:         linear-gradient(#4db6ac, #009688 50%, #80cbc4);
    background-repeat: no-repeat;
}

.bg-gradient-radial-teal
{
    background-image: -webkit-radial-gradient(circle, #00796b, #4db6ac);
    background-image:    -moz-radial-gradient(circle, #00796b, #4db6ac);
    background-image:      -o-radial-gradient(circle, #00796b, #4db6ac);
    background-image:         radial-gradient(circle, #00796b, #4db6ac);
    background-repeat: no-repeat;
}

.bg-gradient-striped-teal
{
    background-image: -webkit-linear-gradient(45deg, #80cbc4 25%, transparent 25%, transparent 50%, #80cbc4 50%, #80cbc4 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #80cbc4 25%, transparent 25%, transparent 50%, #80cbc4 50%, #80cbc4 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #80cbc4 25%, transparent 25%, transparent 50%, #80cbc4 50%, #80cbc4 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #80cbc4 25%, transparent 25%, transparent 50%, #80cbc4 50%, #80cbc4 75%, transparent 75%, transparent);
}

.bg-gradient-x-teal .card-header,
.bg-gradient-x-teal .card-footer,
.bg-gradient-y-teal .card-header,
.bg-gradient-y-teal .card-footer,
.bg-gradient-directional-teal .card-header,
.bg-gradient-directional-teal .card-footer,
.bg-gradient-radial-teal .card-header,
.bg-gradient-radial-teal .card-footer,
.bg-gradient-striped-teal .card-header,
.bg-gradient-striped-teal .card-footer,
.bg-gradient-x2-teal .card-header,
.bg-gradient-x2-teal .card-footer,
.bg-gradient-y2-teal .card-header,
.bg-gradient-y2-teal .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-yellow
{
    background-image: -webkit-gradient(linear, left top, right top, from(#fbc02d), to(#fff176));
    background-image: -webkit-linear-gradient(left, #fbc02d 0%, #fff176 100%);
    background-image:    -moz-linear-gradient(left, #fbc02d 0%, #fff176 100%);
    background-image:      -o-linear-gradient(left, #fbc02d 0%, #fff176 100%);
    background-image:         linear-gradient(to right, #fbc02d 0%, #fff176 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-yellow
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fbc02d), to(#fff176));
    background-image: -webkit-linear-gradient(top, #fbc02d 0%, #fff176 100%);
    background-image:    -moz-linear-gradient(top, #fbc02d 0%, #fff176 100%);
    background-image:      -o-linear-gradient(top, #fbc02d 0%, #fff176 100%);
    background-image:         linear-gradient(to bottom, #fbc02d 0%, #fff176 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-yellow
{
    background-image: -webkit-linear-gradient(45deg, #fbc02d, #fff176);
    background-image:    -moz-linear-gradient(45deg, #fbc02d, #fff176);
    background-image:      -o-linear-gradient(45deg, #fbc02d, #fff176);
    background-image:         linear-gradient(45deg, #fbc02d, #fff176);
    background-repeat: repeat-x;
}

.bg-gradient-x2-yellow
{
    background-image: -webkit-gradient(linear, left top, right top, from(#fff176), color-stop(50%, #ffeb3b), to(#fff59d));
    background-image: -webkit-linear-gradient(left, #fff176, #ffeb3b 50%, #fff59d);
    background-image:    -moz-linear-gradient(left, #fff176, #ffeb3b 50%, #fff59d);
    background-image:      -o-linear-gradient(left, #fff176, #ffeb3b 50%, #fff59d);
    background-image:         linear-gradient(to right, #fff176, #ffeb3b 50%, #fff59d);
    background-repeat: no-repeat;
}

.bg-gradient-y2-yellow
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff176), color-stop(50%, #ffeb3b), to(#fff59d));
    background-image: -webkit-linear-gradient(#fff176, #ffeb3b 50%, #fff59d);
    background-image:    -moz-linear-gradient(#fff176, #ffeb3b 50%, #fff59d);
    background-image:      -o-linear-gradient(#fff176, #ffeb3b 50%, #fff59d);
    background-image:         linear-gradient(#fff176, #ffeb3b 50%, #fff59d);
    background-repeat: no-repeat;
}

.bg-gradient-radial-yellow
{
    background-image: -webkit-radial-gradient(circle, #fbc02d, #fff176);
    background-image:    -moz-radial-gradient(circle, #fbc02d, #fff176);
    background-image:      -o-radial-gradient(circle, #fbc02d, #fff176);
    background-image:         radial-gradient(circle, #fbc02d, #fff176);
    background-repeat: no-repeat;
}

.bg-gradient-striped-yellow
{
    background-image: -webkit-linear-gradient(45deg, #fff59d 25%, transparent 25%, transparent 50%, #fff59d 50%, #fff59d 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #fff59d 25%, transparent 25%, transparent 50%, #fff59d 50%, #fff59d 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #fff59d 25%, transparent 25%, transparent 50%, #fff59d 50%, #fff59d 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #fff59d 25%, transparent 25%, transparent 50%, #fff59d 50%, #fff59d 75%, transparent 75%, transparent);
}

.bg-gradient-x-yellow .card-header,
.bg-gradient-x-yellow .card-footer,
.bg-gradient-y-yellow .card-header,
.bg-gradient-y-yellow .card-footer,
.bg-gradient-directional-yellow .card-header,
.bg-gradient-directional-yellow .card-footer,
.bg-gradient-radial-yellow .card-header,
.bg-gradient-radial-yellow .card-footer,
.bg-gradient-striped-yellow .card-header,
.bg-gradient-striped-yellow .card-footer,
.bg-gradient-x2-yellow .card-header,
.bg-gradient-x2-yellow .card-footer,
.bg-gradient-y2-yellow .card-header,
.bg-gradient-y2-yellow .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-amber
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ffa000), to(#ffd54f));
    background-image: -webkit-linear-gradient(left, #ffa000 0%, #ffd54f 100%);
    background-image:    -moz-linear-gradient(left, #ffa000 0%, #ffd54f 100%);
    background-image:      -o-linear-gradient(left, #ffa000 0%, #ffd54f 100%);
    background-image:         linear-gradient(to right, #ffa000 0%, #ffd54f 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-amber
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffa000), to(#ffd54f));
    background-image: -webkit-linear-gradient(top, #ffa000 0%, #ffd54f 100%);
    background-image:    -moz-linear-gradient(top, #ffa000 0%, #ffd54f 100%);
    background-image:      -o-linear-gradient(top, #ffa000 0%, #ffd54f 100%);
    background-image:         linear-gradient(to bottom, #ffa000 0%, #ffd54f 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-amber
{
    background-image: -webkit-linear-gradient(45deg, #ffa000, #ffd54f);
    background-image:    -moz-linear-gradient(45deg, #ffa000, #ffd54f);
    background-image:      -o-linear-gradient(45deg, #ffa000, #ffd54f);
    background-image:         linear-gradient(45deg, #ffa000, #ffd54f);
    background-repeat: repeat-x;
}

.bg-gradient-x2-amber
{
    background-image: -webkit-gradient(linear, left top, right top, from(#ffd54f), color-stop(50%, #ffc107), to(#ffe082));
    background-image: -webkit-linear-gradient(left, #ffd54f, #ffc107 50%, #ffe082);
    background-image:    -moz-linear-gradient(left, #ffd54f, #ffc107 50%, #ffe082);
    background-image:      -o-linear-gradient(left, #ffd54f, #ffc107 50%, #ffe082);
    background-image:         linear-gradient(to right, #ffd54f, #ffc107 50%, #ffe082);
    background-repeat: no-repeat;
}

.bg-gradient-y2-amber
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffd54f), color-stop(50%, #ffc107), to(#ffe082));
    background-image: -webkit-linear-gradient(#ffd54f, #ffc107 50%, #ffe082);
    background-image:    -moz-linear-gradient(#ffd54f, #ffc107 50%, #ffe082);
    background-image:      -o-linear-gradient(#ffd54f, #ffc107 50%, #ffe082);
    background-image:         linear-gradient(#ffd54f, #ffc107 50%, #ffe082);
    background-repeat: no-repeat;
}

.bg-gradient-radial-amber
{
    background-image: -webkit-radial-gradient(circle, #ffa000, #ffd54f);
    background-image:    -moz-radial-gradient(circle, #ffa000, #ffd54f);
    background-image:      -o-radial-gradient(circle, #ffa000, #ffd54f);
    background-image:         radial-gradient(circle, #ffa000, #ffd54f);
    background-repeat: no-repeat;
}

.bg-gradient-striped-amber
{
    background-image: -webkit-linear-gradient(45deg, #ffe082 25%, transparent 25%, transparent 50%, #ffe082 50%, #ffe082 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #ffe082 25%, transparent 25%, transparent 50%, #ffe082 50%, #ffe082 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #ffe082 25%, transparent 25%, transparent 50%, #ffe082 50%, #ffe082 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #ffe082 25%, transparent 25%, transparent 50%, #ffe082 50%, #ffe082 75%, transparent 75%, transparent);
}

.bg-gradient-x-amber .card-header,
.bg-gradient-x-amber .card-footer,
.bg-gradient-y-amber .card-header,
.bg-gradient-y-amber .card-footer,
.bg-gradient-directional-amber .card-header,
.bg-gradient-directional-amber .card-footer,
.bg-gradient-radial-amber .card-header,
.bg-gradient-radial-amber .card-footer,
.bg-gradient-striped-amber .card-header,
.bg-gradient-striped-amber .card-footer,
.bg-gradient-x2-amber .card-header,
.bg-gradient-x2-amber .card-footer,
.bg-gradient-y2-amber .card-header,
.bg-gradient-y2-amber .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-blue-grey
{
    background-image: -webkit-gradient(linear, left top, right top, from(#455a64), to(#90a4ae));
    background-image: -webkit-linear-gradient(left, #455a64 0%, #90a4ae 100%);
    background-image:    -moz-linear-gradient(left, #455a64 0%, #90a4ae 100%);
    background-image:      -o-linear-gradient(left, #455a64 0%, #90a4ae 100%);
    background-image:         linear-gradient(to right, #455a64 0%, #90a4ae 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-blue-grey
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#455a64), to(#90a4ae));
    background-image: -webkit-linear-gradient(top, #455a64 0%, #90a4ae 100%);
    background-image:    -moz-linear-gradient(top, #455a64 0%, #90a4ae 100%);
    background-image:      -o-linear-gradient(top, #455a64 0%, #90a4ae 100%);
    background-image:         linear-gradient(to bottom, #455a64 0%, #90a4ae 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-blue-grey
{
    background-image: -webkit-linear-gradient(45deg, #455a64, #90a4ae);
    background-image:    -moz-linear-gradient(45deg, #455a64, #90a4ae);
    background-image:      -o-linear-gradient(45deg, #455a64, #90a4ae);
    background-image:         linear-gradient(45deg, #455a64, #90a4ae);
    background-repeat: repeat-x;
}

.bg-gradient-x2-blue-grey
{
    background-image: -webkit-gradient(linear, left top, right top, from(#90a4ae), color-stop(50%, #607d8b), to(#b0bec5));
    background-image: -webkit-linear-gradient(left, #90a4ae, #607d8b 50%, #b0bec5);
    background-image:    -moz-linear-gradient(left, #90a4ae, #607d8b 50%, #b0bec5);
    background-image:      -o-linear-gradient(left, #90a4ae, #607d8b 50%, #b0bec5);
    background-image:         linear-gradient(to right, #90a4ae, #607d8b 50%, #b0bec5);
    background-repeat: no-repeat;
}

.bg-gradient-y2-blue-grey
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#90a4ae), color-stop(50%, #607d8b), to(#b0bec5));
    background-image: -webkit-linear-gradient(#90a4ae, #607d8b 50%, #b0bec5);
    background-image:    -moz-linear-gradient(#90a4ae, #607d8b 50%, #b0bec5);
    background-image:      -o-linear-gradient(#90a4ae, #607d8b 50%, #b0bec5);
    background-image:         linear-gradient(#90a4ae, #607d8b 50%, #b0bec5);
    background-repeat: no-repeat;
}

.bg-gradient-radial-blue-grey
{
    background-image: -webkit-radial-gradient(circle, #455a64, #90a4ae);
    background-image:    -moz-radial-gradient(circle, #455a64, #90a4ae);
    background-image:      -o-radial-gradient(circle, #455a64, #90a4ae);
    background-image:         radial-gradient(circle, #455a64, #90a4ae);
    background-repeat: no-repeat;
}

.bg-gradient-striped-blue-grey
{
    background-image: -webkit-linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
}

.bg-gradient-x-blue-grey .card-header,
.bg-gradient-x-blue-grey .card-footer,
.bg-gradient-y-blue-grey .card-header,
.bg-gradient-y-blue-grey .card-footer,
.bg-gradient-directional-blue-grey .card-header,
.bg-gradient-directional-blue-grey .card-footer,
.bg-gradient-radial-blue-grey .card-header,
.bg-gradient-radial-blue-grey .card-footer,
.bg-gradient-striped-blue-grey .card-header,
.bg-gradient-striped-blue-grey .card-footer,
.bg-gradient-x2-blue-grey .card-header,
.bg-gradient-x2-blue-grey .card-footer,
.bg-gradient-y2-blue-grey .card-header,
.bg-gradient-y2-blue-grey .card-footer
{
    background-color: transparent;
}

.bg-gradient-x-grey-blue
{
    background-image: -webkit-gradient(linear, left top, right top, from(#404e67), to(#6f85ad));
    background-image: -webkit-linear-gradient(left, #404e67 0%, #6f85ad 100%);
    background-image:    -moz-linear-gradient(left, #404e67 0%, #6f85ad 100%);
    background-image:      -o-linear-gradient(left, #404e67 0%, #6f85ad 100%);
    background-image:         linear-gradient(to right, #404e67 0%, #6f85ad 100%);
    background-repeat: repeat-x;
}

.bg-gradient-y-grey-blue
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#404e67), to(#6f85ad));
    background-image: -webkit-linear-gradient(top, #404e67 0%, #6f85ad 100%);
    background-image:    -moz-linear-gradient(top, #404e67 0%, #6f85ad 100%);
    background-image:      -o-linear-gradient(top, #404e67 0%, #6f85ad 100%);
    background-image:         linear-gradient(to bottom, #404e67 0%, #6f85ad 100%);
    background-repeat: repeat-x;
}

.bg-gradient-directional-grey-blue
{
    background-image: -webkit-linear-gradient(45deg, #404e67, #6f85ad);
    background-image:    -moz-linear-gradient(45deg, #404e67, #6f85ad);
    background-image:      -o-linear-gradient(45deg, #404e67, #6f85ad);
    background-image:         linear-gradient(45deg, #404e67, #6f85ad);
    background-repeat: repeat-x;
}

.bg-gradient-x2-grey-blue
{
    background-image: -webkit-gradient(linear, left top, right top, from(#6f85ad), color-stop(50%, #1b2942), to(#b0bec5));
    background-image: -webkit-linear-gradient(left, #6f85ad, #1b2942 50%, #b0bec5);
    background-image:    -moz-linear-gradient(left, #6f85ad, #1b2942 50%, #b0bec5);
    background-image:      -o-linear-gradient(left, #6f85ad, #1b2942 50%, #b0bec5);
    background-image:         linear-gradient(to right, #6f85ad, #1b2942 50%, #b0bec5);
    background-repeat: no-repeat;
}

.bg-gradient-y2-grey-blue
{
    background-image: -webkit-gradient(linear, left top, left bottom, from(#6f85ad), color-stop(50%, #1b2942), to(#b0bec5));
    background-image: -webkit-linear-gradient(#6f85ad, #1b2942 50%, #b0bec5);
    background-image:    -moz-linear-gradient(#6f85ad, #1b2942 50%, #b0bec5);
    background-image:      -o-linear-gradient(#6f85ad, #1b2942 50%, #b0bec5);
    background-image:         linear-gradient(#6f85ad, #1b2942 50%, #b0bec5);
    background-repeat: no-repeat;
}

.bg-gradient-radial-grey-blue
{
    background-image: -webkit-radial-gradient(circle, #404e67, #6f85ad);
    background-image:    -moz-radial-gradient(circle, #404e67, #6f85ad);
    background-image:      -o-radial-gradient(circle, #404e67, #6f85ad);
    background-image:         radial-gradient(circle, #404e67, #6f85ad);
    background-repeat: no-repeat;
}

.bg-gradient-striped-grey-blue
{
    background-image: -webkit-linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
    background-image:    -moz-linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
    background-image:      -o-linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
    background-image:         linear-gradient(45deg, #b0bec5 25%, transparent 25%, transparent 50%, #b0bec5 50%, #b0bec5 75%, transparent 75%, transparent);
}

.bg-gradient-x-grey-blue .card-header,
.bg-gradient-x-grey-blue .card-footer,
.bg-gradient-y-grey-blue .card-header,
.bg-gradient-y-grey-blue .card-footer,
.bg-gradient-directional-grey-blue .card-header,
.bg-gradient-directional-grey-blue .card-footer,
.bg-gradient-radial-grey-blue .card-header,
.bg-gradient-radial-grey-blue .card-footer,
.bg-gradient-striped-grey-blue .card-header,
.bg-gradient-striped-grey-blue .card-footer,
.bg-gradient-x2-grey-blue .card-header,
.bg-gradient-x2-grey-blue .card-footer,
.bg-gradient-y2-grey-blue .card-header,
.bg-gradient-y2-grey-blue .card-footer
{
    background-color: transparent;
}
