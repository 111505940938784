@import url("square/blue.css");
/*
@import url("square/_all.css");
@import url("square/square.css");
@import url("square/red.css");
@import url("square/green.css");
@import url("square/blue.css");
@import url("square/aero.css");
@import url("square/grey.css");
@import url("square/orange.css");
@import url("square/yellow.css");
@import url("square/pink.css");
@import url("square/purple.css");
*/

/*
@import url("polaris/polaris.css");

@import url("futurico/futurico.css");
*/